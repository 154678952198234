#mainNav {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  //background-color: #fff;
  transition: background-color 0.2s ease;
}
#mainNav.dark{
  background: rgba(0, 0, 0, 0.7);
}
#mainNav .navbar-brand {
  font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #212529;
}
#mainNav .navbar-nav .nav-item .nav-link {
  color: #6c757d;
  font-family: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  font-size: 0.9rem;
  padding: 0.75rem 0;
}
#mainNav .navbar-nav .nav-item .nav-link:hover, #mainNav .navbar-nav .nav-item .nav-link:active {
  color: #f4623a;
}
#mainNav .navbar-nav .nav-item .nav-link.active {
  color: #f4623a !important;
}
@media (min-width: 992px) {
  #mainNav {
    box-shadow: none;
    background-color: transparent;
  }
  #mainNav .navbar-brand {
    color: rgba(255, 255, 255, 0.95);
  }
  #mainNav .navbar-brand:hover {
    color: #fff;
  }
  #mainNav .navbar-nav .nav-item .nav-link {
    color: rgba(255, 255, 255, 0.95);
    padding: 1.25rem 1rem;
  }
  #mainNav .navbar-nav .nav-item .nav-link:hover {
    color: #fff;
  }
  #mainNav .navbar-nav .nav-item:last-child .nav-link {
    padding-right: 0;
  }
  #mainNav.navbar-scrolled {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    background-color: #fff;
  }
  #mainNav.navbar-scrolled .navbar-brand {
    color: #212529;
  }
  #mainNav.navbar-scrolled .navbar-brand:hover {
    color: #f4623a;
  }
  #mainNav.navbar-scrolled .navbar-nav .nav-item .nav-link {
    color: #212529;
  }
  #mainNav.navbar-scrolled .navbar-nav .nav-item .nav-link:hover {
    color: #f4623a;
  }
}


#navbarResponsive{
  @media (max-width: 992px) {
    //display: none;
    display: none;
    &.show{
      position: absolute;
      top: 75px;
      background: #ffffffe0;
      width: 50%;
      MARGIN: AUTO;
      PADDING: 15PX;
      display: block;
    }
  }
}

.navbar-toggler {
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent !important;
  color: red;
  border-radius: .25rem;
  transition: box-shadow .15s ease-in-out;
}
