//.main-section-container {
//  &:after{
//    background-image: -webkit-linear-gradient(top, #ccc, #777);
//    background-image: -moz-linear-gradient(top, #ccc, #777);
//    background-image: -o-linear-gradient(top, #ccc, #777);
//    background-image: -ms-linear-gradient(top, #ccc, #777);
//    background-image: linear-gradient(top, #ccc, #777);
//  }
//  height: 100vh;
//  //background-image:url('../public/assets/images/main_bg.jpeg');
//}

#busmarket{
  padding-top: 180px;
  padding-bottom: 180px;
  //background-color: #ffffff;
}

//@media (min-width: 1200px) {
//  header.masthead h1 {
//
//  }
//}

.busmarket-section{
  background-color: #fafafa;
}

.title{
  font-size: 2.5rem;
  line-height: 3rem ;
  font-weight: 600;
}

.text{
  font-size: 1rem;
  line-height: 1.55;
  font-weight: lighter;
}

.gallery{
  display: flex;
  flex-wrap: wrap;
  &-item{
    width:calc(100% / 4);
    @media (max-width: 980px) and (min-width: 720px) {
      width: calc(100% / 3);
    }
    @media (max-width: 719.99px) and (min-width: 641px)  {
      width: calc(100% / 2);
    }
    @media (max-width: 641px)  {
      width: calc(100% / 1);
    }
    padding: 10px;

    img{
      border-radius: 10px;
      height: auto;
      max-width: 100%;
    }
  }
}
