

header.automotive {
  padding-top: 10rem;
  padding-bottom: calc(10rem - 4.5rem);
  background: url(../../../../../src/assets/images/parts_bg.jpeg);
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  .container{
    z-index: 20;
    position: relative;
  }

  .cover {
    background: rgba(0,0,0,0.9);
    background: -moz-linear-gradient(top, rgba(0,0,0,0.9) 0%, rgba(237,237,237,0.1) 100%);
    background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,0,0,0.9)), color-stop(100%, rgba(237,237,237,0.1)));
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.9) 0%, rgba(237,237,237,0.1) 100%);
    background: -o-linear-gradient(top, rgba(0,0,0,0.9) 0%, rgba(237,237,237,0.1) 100%);
    background: -ms-linear-gradient(top, rgba(0,0,0,0.9) 0%, rgba(237,237,237,0.1) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0.9) 0%, rgba(237,237,237,0.1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ededed', GradientType=0 );

    height: 100%;
    top: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
}



header.automotive h1 {
  font-size: 2.25rem;
}

@media (min-width: 992px) {
  header.automotive {
    height: 40vh;
    min-height: 40rem;
    padding-top: 4.5rem;
    padding-bottom: 0;
  }
  header.automotive p {
    font-size: 1.15rem;
    text-align: left;
  }
  header.automotive h1 {
    text-align: left;
  }
}

@media (min-width: 1200px) {
  header.automotive h1 {
    font-size: 2.5rem;
    line-height: 3rem ;
  }
}

.label{
  color: #d95151;
  text-transform: uppercase;
  font-family: 'Roboto',Arial,sans-serif;
  font-weight: 700;
  font-size: .65rem;
  letter-spacing: 2.5px;
}
