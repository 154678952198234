
#about{
  padding-top: 180px;
  padding-bottom: 180px;
  //background-color: #ffffff;
}


.title{
  font-size: 2.5rem;
  line-height: 3rem ;
  font-weight: 600;
}

.text{
  font-size: 1.4rem;
  line-height: 1.55;
  font-weight: lighter;
}


.contact-form{
  background: #fafafa;
  border:none;
  box-shadow: 0 0 50px #e6e6e6;
  border-radius: 15px;
}
